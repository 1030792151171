@import 'colors';

/* change colors also in pspdf.css */
// $wd-primary: $primary;
$wd-primary: $primary;
$wd-accent: $accent;
$wd-warn: #f2506b;
$wd-primary-light: lighten($wd-primary, 5%);
$wd-blue: $blue;
$wd-blue-light: lighten($wd-blue, 7%);
$wd-purple: $purple;
$wd-purple-light: lighten($wd-purple, 7%);
$wd-green: $green;
$wd-yellow: $yellow;
$wd-red: $wd-warn;
$wd-orange: $orange;
$wd-background: #c6d5c8;
$wd-gray: $gray;
$wd-text: #3d474d;
$wd-white: #ffffff;
$wd-black: #000000;
$wd-light-background: $light-background;
$wd-dark-background: $dark-background;
$wd-gray-background: $gray-background;
$wd-dark-primary-text: $wd-white;
