@import './../shared/styles/base_colors';

@mixin auth-component-theme($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .logout-loading {
    color: $wd-text;
  }

  .login-container {
    box-shadow: 0 14px 28px rgba(black, 0.08), 0 10px 10px rgba(black, 0.02);
    // border: 1px solid rgba(black, 0.12);
    .left {
      background: lighten($wd-primary, 30%);
    }
    .right {
      background: white;
    }
  }

  @media screen and (min-width: 600px) {
    .bottom-links {
      color: darken($wd-text, 10%);
      a {
        color: $wd-text;
      }
    }

    .auth-layout.login {
      background: linear-gradient(45deg, darken(#f2f2f2, 10%) 0%, lighten(#f2f2f2, 10%) 100%);
    }
  }
}

@mixin auth-component-theme-dark($theme) {
  // retrieve variables from theme
  // (all possible variables, use only what you really need)
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  @media screen and (min-width: 600px) {
    .bottom-links {
      color: darken(white, 10%);
      a {
        color: white;
      }
    }

    .auth-layout.login {
      background: linear-gradient(45deg, rgba($wd-accent, 0.5) 0%, rgba($wd-primary, 0.5) 100%);
    }
  }

  .logout-loading {
    color: white;
  }

  .login-container {
    // border: 1px solid rgba(white, 0.12);
    box-shadow: 0 14px 28px rgba(black, 0.18), 0 10px 10px rgba(black, 0.12);
    .left {
      background: lighten($wd-primary, 40%);
      // background: darken(white, 10%); // darken($wd-primary, 30%);
    }
    .right {
      background: $wd-dark-background;
    }
  }
}
