$primary: #35b9c6;
$accent: #2e7d9e;
$warn: #f2506b;
$green: #35c69b;
$blue: #41a3e4;
$purple: #9c72c6;
$yellow: #ffa000;
$orange: #e67e22;
$gray: #62747f;
$light-background: #ffffff;
$dark-background: #303030;
$gray-background: #f0f0f0;