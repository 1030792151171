@use '@angular/material' as mat;
@import '../app/shared/styles/base_colors';

$primary: mat.define-palette($mat-primary, main, lighter, darker);
$accent: mat.define-palette($mat-primary, main, lighter, darker);
$warn: mat.define-palette($mat-warn, main, lighter, darker);
$background: mat.define-palette($mat-background-dark, main, lighter, darker);
$wedecide-dark-theme: mat.define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography:
      mat.define-typography-config(
        $button:
          mat.define-typography-level(
            $font-family: 'Roboto, sans-serif',
            $font-weight: 500,
            $font-size: 14px,
            $line-height: 36px,
            $letter-spacing: normal,
          ),
      ),
    density: 0,
  )
);

.wedecide-dark-theme {
  @include mat.all-component-themes($wedecide-dark-theme);
  background: $wd-dark-background;

  h1,
  h2,
  h3,
  h4,
  h5,
  h5 {
    color: white;
  }

  .wd-accent {
    color: mat.get-color-from-palette($accent) !important;
  }

  .bg-wd-accent {
    background-color: mat.get-color-from-palette($accent) !important;
  }

  .wd-default {
    color: $wd-white !important;
  }

  a {
    color: mat.get-color-from-palette($primary);
  }

  .display-chip {
    background-color: #616161;
    color: white;
  }

  .mat-icon[svgicon] {
    svg path {
      fill: white;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: none !important;
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
    transition: background-color 5000s ease-in-out 0s;
  }

  .nda-check {
    color: darken(white, 35%);
  }

  .warning {
    color: mat.get-color-from-palette($warn);
  }

  .light-text {
    color: lighten(#000, 75%);

    .mat-icon svg path {
      fill: lighten(#000, 50%);
      stroke: lighten(#000, 50%);
    }
  }

  [contenteditable='true']:empty:before {
    color: darken(#fff, 50%);
  }

  .manage-container .column .disabled-column {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAN0lEQVQoU2NkIAIwEqHmPyFF/xkYGBgJKQJbhEsR2ASYU7ApQlGAzyQU/yCbhGECunU4FYAUAgBp4wYJ31jKswAAAABJRU5ErkJggg==)
      repeat;
  }

  .mat-mdc-tooltip.navbar-tooltip {
    background: rgba(black, 90%) !important;
    color: white;
  }

  // add dark theme styles
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  mat-card {
    color: white;

    &:focus,
    &.focused {
      border: 1px solid mat.get-color-from-palette($primary);
      outline: none;
    }

    box-shadow: rgba(0, 0, 0, 0.02) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px,
      rgba(0, 0, 0, 0.05) 0px 2px 8px 0px !important;

    &.item-card {
      border: 1px solid transparent;
    }

    &.no-hover {
      border: 1px solid rgba(255, 255, 255, 0.12) !important;
      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.12) !important;
      }
    }
  }
  .mdc-linear-progress__buffer {
    background: darken(#424242, 5%) !important;
  }

  .dialog-footer-input {
    background: $wd-dark-background;
  }

  .dialog-backdrop {
    background: rgba(0, 0, 0, 0.6);
  }

  .board-chip {
    background: lighten($wd-dark-background, 10%);
    color: mat.get-color-from-palette($primary);
    border: 1px solid lighten($wd-dark-background, 15%);
  }

  .role {
    background: lighten($wd-dark-background, 10%);
    color: mat.get-color-from-palette($primary);
    border: 1px solid lighten($wd-dark-background, 15%);
  }

  .chip {
    background-color: rgba($wd-primary, 20%);
    color: $wd-primary;
  }

  .mat-mdc-card:not([class*='mat-elevation-z']) {
    &.no-shadow {
      border: 1px solid rgba(white, 0.12);
    }
  }

  // text color of labels
  .ngx-charts {
    text {
      fill: #fff;
    }
  }

  .r6o-editor {
    border: 1px solid #5f5f5f;
    border-radius: 5px;
    .r6o-arrow {
      color: #313131;
      background-color: transparent;

      &:after {
        background-color: #31393e;
        border: 1px solid #5f5f5f;
      }
    }

    .r6o-arrow-down {
      background-color: #313131;
    }
    .r6o-comment-dropdown-menu {
      border-radius: 5px;
      background-color: #313131;

      li {
        &:hover {
          background-color: #5f5f5f;
        }
      }
    }

    .r6o-lastmodified {
      border: 1px solid #5f5f5f;
    }

    .r6o-widget {
      border-color: #5f5f5f;
    }

    .r6o-editor-inner {
      background-color: #31393e;
      color: whitesmoke;
      border: none;

      &:hover {
        border: 1px solid #5f5f5f;
      }
      .comment {
        border: 1px solid #5f5f5f;
      }

      .r6o-editable-text {
        color: whitesmoke;
      }

      .r6o-btn {
        background-color: #35b9c6;
        color: whitesmoke;

        &.outline {
          background: transparent;
          border: 1 solid whitesmoke;
          color: whitesmoke;

          &:hover {
            background-color: #5f5f5f;
          }
        }
      }
    }
  }

  .bottom-bar-container {
    background: darken(#424242, 5%) !important;
  }

  mark {
    background: rgba($wd-primary, 80%) !important;
    color: white !important;
  }
  .mat-mdc-form-field.transparent.mat-form-field-appearance-fill .mat-mdc-form-field-flex {
    background-color: transparent !important;
  }

  .edit-todo-sticky-container,
  .proposal-sticky-container {
    background: rgba($wd-dark-background, 0.94) !important;
  }

  .mdc-dialog__container {
    color: white;
  }

  .mat-mdc-checkbox {
    --mdc-checkbox-unselected-icon-color: white !important;
    --mdc-checkbox-disabled-selected-icon-color: #686868;
    .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      background-color: rgb(0 0 0 / 10%) !important;
      opacity: 0.5;
    }
  }

  .todo-overview-container {
    background: $wd-dark-background;
  }
}

.duedate-chip {
  &.gray {
    background-color: rgba(lighten($wd-gray, 10%), 20%); //rgba($wd-gray, 20%);
    color: lighten($wd-gray, 15%) !important;
  }
}

.wd-gray {
  color: lighten($wd-gray, 15%) !important;
}

.toastui-editor-defaultUI .toastui-editor-ok-button {
  background-color: $wd-primary;
  color: #fff;
  outline-color: $wd-primary;

  &:hover {
    background-color: lighten($wd-primary, 5%);
  }
}

